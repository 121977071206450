import React from "react";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";
import GatsbyImage from "gatsby-image";
import { graphql } from "gatsby";
import "bootstrap/dist/css/bootstrap.min.css";
import ButtonBanner from "../components/ButtonBanner";
import "../styles/tech.scss";
import { Container } from "react-bootstrap";

const PrivacyPolicy = ({ data }) => {
  return (
    <Layout>
      <Helmet>
        <title>HelloAR: Privacy Policy</title>
        <meta name="description" content="Our privacy policy" />
        <meta
          name="keywords"
          content="ar vr technology, augmented reality technologies"
        />
        <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
        <meta httpEquiv="Content-Type" content="text/html;charset=UTF-8" />
        <meta property="og:image" content="../../images/auto/car_360.jpg" />
      </Helmet>
      <Container fluid className="tech">
        <div className="spacer d-md-none"></div>
        <div className="spacer d-xl-none"></div>
        <div className="spacer-sm"></div>
        <div className="container">
          <div className="spacer d-xl-none"></div>
          <div className="spacer-sm"></div>
          <div>
            <h1 className="h1 text-center">Privacy policy</h1>
            <div className="spacer-sm"></div>

            <p className="bt-1 mt-5">
              At helloar.io, accessible from helloar.io, one of our main
              priorities is the privacy of our visitors. This Privacy Policy
              document contains types of information that is collected and
              recorded by helloar.io and how we use it.
            </p>
            <p className="bt-1 mt-3">
              If you have additional questions or require more information about
              our Privacy Policy, do not hesitate to contact us.
            </p>
          </div>
          <div className="spacer-sm"></div>
          <div className="section">
            <ul className="mt-3">
              <li className="row">
                <h2 className="col-12 col-md-3">Log Files</h2>
                <p className="bt-1 col-12 col-md-9 main">
                  helloar.io follows a standard procedure of using log files.
                  These files log visitors when they visit websites. All hosting
                  companies do this and a part of hosting services' analytics.
                  The information collected by log files include internet
                  protocol (IP) addresses, browser type, Internet Service
                  Provider (ISP), date and time stamp, referring/exit pages, and
                  possibly the number of clicks. These are not linked to any
                  information that is personally identifiable. The purpose of
                  the information is for analyzing trends, administering the
                  site, tracking users' movement on the website, and gathering
                  demographic information.
                </p>
              </li>
              <div className="spacer-sm"></div>

              <li className="row">
                <h2 className="col-12 col-md-3">Cookies and Web Beacons</h2>
                <p className="bt-1 col-12 col-md-9 main">
                  Like any other website, helloar.io uses 'cookies'. These
                  cookies are used to store information including visitors'
                  preferences, and the pages on the website that the visitor
                  accessed or visited. The information is used to optimize the
                  users' experience by customizing our web page content based on
                  visitors' browser type and/or other information. <br />
                  For more general information on cookies, please read the "What
                  Are Cookies" article on{" "}
                  <a href="https://www.termsfeed.com/blog/cookies/">
                    Cookie Consent website
                  </a>
                  .
                </p>
              </li>
              <div className="spacer-sm"></div>

              <li className="row">
                <h2 className="col-12 col-md-3">Privacy Policies</h2>
                <p className="bt-1 col-12 col-md-9 main">
                  You may consult this list to find the Privacy Policy for each
                  of the advertising partners of helloar.io. <br />
                  <br />
                  Third-party ad servers or ad networks uses technologies like
                  cookies, JavaScript, or Web Beacons that are used in their
                  respective advertisements and links that appear on helloar.io,
                  which are sent directly to users' browser. They automatically
                  receive your IP address when this occurs. These technologies
                  are used to measure the effectiveness of their advertising
                  campaigns and/or to personalize the advertising content that
                  you see on websites that you visit.
                  <br /> <br />
                  Note that helloar.io has no access to or control over these
                  cookies that are used by third-party advertisers.
                </p>
              </li>
              <div className="spacer-sm"></div>

              <li className="row">
                <h2 className="col-12 col-md-3">
                  Third Party Privacy Policies
                </h2>
                <p className="bt-1 col-12 col-md-9 main">
                  Thelloar.io's Privacy Policy does not apply to other
                  advertisers or websites. Thus, we are advising you to consult
                  the respective Privacy Policies of these third-party ad
                  servers for more detailed information. It may include their
                  practices and instructions about how to opt-out of certain
                  options. <br /> <br />
                  You can choose to disable cookies through your individual
                  browser options. To know more detailed information about
                  cookie management with specific web browsers, it can be found
                  at the browsers' respective websites. What Are Cookies?
                </p>
              </li>
              <div className="spacer-sm"></div>

              <li className="row">
                <h2 className="col-12 col-md-3">Children's Information</h2>
                <p className="bt-1 col-12 col-md-9 main">
                  Another part of our priority is adding protection for children
                  while using the internet. We encourage parents and guardians
                  to observe, participate in, and/or monitor and guide their
                  online activity. <br />
                  <br />
                  helloar.io does not knowingly collect any Personal
                  Identifiable Information from children under the age of 13. If
                  you think that your child provided this kind of information on
                  our website, we strongly encourage you to contact us
                  immediately and we will do our best efforts to promptly remove
                  such information from our records.
                </p>
              </li>
              <div className="spacer-sm"></div>

              <li className="row">
                <h2 className="col-12 col-md-3">Online Privacy Policy Only</h2>
                <p className="bt-1 col-12 col-md-9 main">
                  This Privacy Policy applies only to our online activities and
                  is valid for visitors to our website with regards to the
                  information that they shared and/or collect in helloar.io.
                  This policy is not applicable to any information collected
                  offline or via channels other than this website.
                </p>
              </li>
              <div className="spacer-sm"></div>

              <li className="row">
                <h2 className="col-12 col-md-3">
                  Individual Data Subject's Rights - Data Access, Portability
                  and Deletion
                </h2>
                <p className="bt-1 col-12 col-md-9 main">
                  We are committed to helping our customers meet the data
                  subject rights requirements of GDPR. We do not process or
                  store any personal data of the clients or their customers. We
                  do store First name, IP address and company details unless
                  your account is deleted. In which case, we dispose of all data
                  in accordance with our Terms of Service and Privacy Policy,
                  but we will not hold it longer than 60 days. We are aware that
                  if you are working with EU customers, you need to be able to
                  provide them with the ability to access, update, retrieve and
                  remove personal data. As we do not process or store any
                  personal data of your customers, we are GDPR compliant. We've
                  been set up as self service from the start and have always
                  given you access to your data. Our customer support team is
                  here for you to answer any questions you might have about
                  working with the API.
                </p>
              </li>
              <div className="spacer-sm"></div>

              <li className="row">
                <h2 className="col-12 col-md-3">Consent</h2>
                <p className="bt-1 col-12 col-md-9 main">
                  By using our website, you hereby consent to our Privacy Policy
                  and agree to its Terms and Conditions.
                </p>
              </li>
              <div className="spacer-sm"></div>

              <div className="spacer-sm"></div>
            </ul>
          </div>
          <div className="spacer-sm"></div>
        </div>
      </Container>
    </Layout>
  );
};

export default PrivacyPolicy;
